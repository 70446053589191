import React from 'react'
import { PropertyViewModel } from './PropertyViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, NavDropdown, Navbar, Nav, Tabs, Tab, Table, Modal } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png';
import salvar from '../../Imagens/save-16.png';
import adicionar from '../../Imagens/add-16.png';
import editar from '../../Imagens/edit-16.png';
import deletar from '../../Imagens/delete-16.png';

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent, TypeComponent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { confirmAlert } from 'react-confirm-alert';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import { PersonViewController, PersonViewControllerDelegate } from '../Person/PersonViewController';
import { PersonViewModel } from '../Person/PersonViewModel';
import NumberFormat from 'react-number-format'
import moment from 'moment';

interface MyProps {
    navigation: any
    delegate: PropertyViewControllerDelegate
    viewModel: PropertyViewModel
    idPrimary: number
    idPoint: number
    copyProp: boolean
    typeProp: string
}

export interface PropertyViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class PropertyViewController extends React.Component<MyProps> implements PersonViewControllerDelegate {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        tabKey: "main",
        main: [],
        json: {},
        typeProperty: [],
        acessTypeProperty: [],
        ruralPropertySituation: [],
        inputWater: [],
        inputEnergy: [],
        blockFace: [],
        district: [],
        zoning: [],
        formObtaining: [],
        buildingType: [],
        typeHeritage: [],
        situation: [],
        courtSituation: [],
        lotOccupation: [],
        paving: [],
        topography: [],
        pedology: [],
        batchLimitation: [],
        publicRide: [],
        pool: [],
        litigation: [],
        ruralLocation: [],
        showButtonDelete: false,
        showFormProperty: false,
        showAddPerson: false,
        arrayProperty: new Array<any>(),
        arrayResident: new Array<any>(),
        indexSelectProperty: -1,
        indexSelectResident: -1,
        personEdit: null,
        listItens: [],
        thousandSeparator: '.'
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        if (this.props.idPoint > 0) {
            let json = this.state.json as any
            json['sgeppontos'] = this.props.idPoint
        }

        this.loadTypeProperty()
        this.loadInputs()
        this.loadBlockFace()
        this.loadMain()
        this.loadSelect()
        this.loadItens()
        if (this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Propriedade</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="header">
                            <Col xs="12">
                                <Form className="containerFormNotBorder">
                                    <Row>
                                        <Col xs="12">
                                            <Tabs
                                                id="controlled-tabs"
                                                activeKey={this.state.tabKey}
                                                onSelect={(key: any) => this.setTab(key)}>
                                                <Tab eventKey="main" title="Geral">
                                                    <Row className="tab-item-container">
                                                        <Col lg="4" xs="12" className="bottom10 hide">
                                                            <Form.Label>Sequencial</Form.Label>
                                                            <Form.Control
                                                                id="sgeppontos"
                                                                defaultValue={json['sgeppontos'] ? json['sgeppontos'] : ''}
                                                                onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                                            />
                                                        </Col>
                                                        <Col lg="4" xs="12" className="bottom10">
                                                            <Form.Label className="isRequired">Ano Inicial</Form.Label>
                                                            <Form.Control
                                                                id="anoinicial"
                                                                defaultValue={json['anoinicial'] ? json['anoinicial'] : ''}
                                                                maxLength={10}
                                                                onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                                            />
                                                        </Col>
                                                        <Col lg="4" xs="12" className="bottom10">
                                                            <Form.Label className="isRequired">Ano Final</Form.Label>
                                                            <Form.Control
                                                                id="anofinal"
                                                                defaultValue={json['anofinal'] ? json['anofinal'] : ''}
                                                                maxLength={10}
                                                                onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {(this.props.typeProp == 'U' || this.props.typeProp == 'R') &&
                                                        <div>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Inscrição Imobiliária</Form.Label>
                                                                    <Form.Control
                                                                        id="inscricaomunicipal"
                                                                        defaultValue={json['inscricaomunicipal'] ? json['inscricaomunicipal'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Tipo de Acesso</Form.Label>
                                                                    <Select
                                                                        id="sgepproptpacesso"
                                                                        value={this.formEvent.onValueSelect('select', 'sgepproptpacesso', this.state.acessTypeProperty)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgepproptpacesso")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.acessTypeProperty}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Tipo de Patrimônio</Form.Label>
                                                                    <Select
                                                                        id="sgeptipopatrimonio"
                                                                        value={this.formEvent.onValueSelect('select', 'sgeptipopatrimonio', this.state.typeHeritage)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptipopatrimonio")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.typeHeritage}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Forma de Obtenção</Form.Label>
                                                                    <Select
                                                                        id="sgepformaobtencao"
                                                                        value={this.formEvent.onValueSelect('select', 'sgepformaobtencao', this.state.formObtaining)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgepformaobtencao")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.formObtaining}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Piscina</Form.Label>
                                                                    <Select
                                                                        id="sgeppiscina"
                                                                        value={this.formEvent.onValueSelect('select', 'sgeppiscina', this.state.pool)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgeppiscina")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.pool}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Litígio</Form.Label>
                                                                    <Select
                                                                        id="sgeplitigio"
                                                                        value={this.formEvent.onValueSelect('selectMultiple', 'sgeplitigio', this.state.litigation)}
                                                                        onChange={(e: any) => this.formEvent.onChange("selectMultiple", e, "sgeplitigio")}
                                                                        closeMenuOnSelect={false}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.litigation}
                                                                        isMulti={true}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Número Matrícula</Form.Label>
                                                                    <Form.Control
                                                                        id="numeromatricula"
                                                                        defaultValue={json['numeromatricula'] ? json['numeromatricula'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Data Matrícula</Form.Label>
                                                                    <Form.Control
                                                                        id="datamatricula"
                                                                        maxLength={10}
                                                                        defaultValue={json['datamatricula'] ? this.formEvent.onDateSelect('datamatricula') : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("date", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Área Matrícula (m²)</Form.Label>
                                                                    <Form.Control
                                                                        id="area"
                                                                        defaultValue={json['area'] ? json['area'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                                                                        placeholder="Digite"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label className="isRequired">Distrito</Form.Label>
                                                                    <Select
                                                                        id="sgepdistrito"
                                                                        value={this.formEvent.onValueSelect('select', 'sgepdistrito', this.state.district)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgepdistrito")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.district}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Zoneamento</Form.Label>
                                                                    <Select
                                                                        id="sgepzoneamento"
                                                                        value={this.formEvent.onValueSelect('select', 'sgepzoneamento', this.state.zoning)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgepzoneamento")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.zoning}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>CNS Cartório</Form.Label>
                                                                    <Form.Control
                                                                        id="cnscartorio"
                                                                        defaultValue={json['cnscartorio'] ? json['cnscartorio'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <InputModalSelectComponent
                                                                        id="Sequencial"
                                                                        keyValue="nome"
                                                                        label="Cidade"
                                                                        required={false}
                                                                        placeholder="Procure"
                                                                        showAddButton={false}
                                                                        navigation={this.props.navigation}
                                                                        titleModal="Cidades"
                                                                        table="cidade"
                                                                        valueDefault={json['municipio']}
                                                                        getValue={(id: number, value: string) => this.setCity(id, value)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    {this.props.typeProp == 'U' &&
                                                        <div>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Número Quadra</Form.Label>
                                                                    <Form.Control
                                                                        id="numeroquadra"
                                                                        defaultValue={json['numeroquadra'] ? json['numeroquadra'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Número Lote</Form.Label>
                                                                    <Form.Control
                                                                        id="numerolote"
                                                                        defaultValue={json['numerolote'] ? json['numerolote'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Nome Loteamento</Form.Label>
                                                                    <Form.Control
                                                                        id="nomeloteamento"
                                                                        defaultValue={json['nomeloteamento'] ? json['nomeloteamento'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Ano Loteamento</Form.Label>
                                                                    <Form.Control
                                                                        id="anolotamento"
                                                                        maxLength={4}
                                                                        defaultValue={json['anolotamento'] ? json['anolotamento'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("year", e.target)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    {this.props.typeProp == 'R' &&
                                                        <div>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Número Gleba</Form.Label>
                                                                    <Form.Control
                                                                        id="numerogleba"
                                                                        defaultValue={json['numerogleba'] ? json['numerogleba'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Secção</Form.Label>
                                                                    <Form.Control
                                                                        id="seccao"
                                                                        defaultValue={json['seccao'] ? json['seccao'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Localidade</Form.Label>
                                                                    <Select
                                                                        id="sgeplocalizacaorural"
                                                                        value={this.formEvent.onValueSelect('select', 'sgeplocalizacaorural', this.state.ruralLocation)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgeplocalizacaorural")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.ruralLocation}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>}
                                                    {this.props.typeProp == 'C' &&
                                                        <div>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Inscrição Imobiliária</Form.Label>
                                                                    <Form.Control
                                                                        id="inscricaomunicipal"
                                                                        defaultValue={json['inscricaomunicipal'] ? json['inscricaomunicipal'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Número Quadra</Form.Label>
                                                                    <Form.Control
                                                                        id="numeroquadra"
                                                                        defaultValue={json['numeroquadra'] ? json['numeroquadra'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Número Lote</Form.Label>
                                                                    <Form.Control
                                                                        id="numerolote"
                                                                        defaultValue={json['numerolote'] ? json['numerolote'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label className="isRequired">Distrito</Form.Label>
                                                                    <Select
                                                                        id="sgepdistrito"
                                                                        value={this.formEvent.onValueSelect('select', 'sgepdistrito', this.state.district)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgepdistrito")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.district}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>}
                                                    {this.props.typeProp == 'D' &&
                                                        <div>
                                                            <Row>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label>Inscrição</Form.Label>
                                                                    <Form.Control
                                                                        id="inscricaomunicipal"
                                                                        defaultValue={json['inscricaomunicipal'] ? json['inscricaomunicipal'] : ''}
                                                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                                                    />
                                                                </Col>
                                                                <Col lg="4" xs="12" className="bottom10">
                                                                    <Form.Label className="isRequired">Distrito</Form.Label>
                                                                    <Select
                                                                        id="sgepdistrito"
                                                                        value={this.formEvent.onValueSelect('select', 'sgepdistrito', this.state.district)}
                                                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgepdistrito")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={"Selecione"}
                                                                        options={this.state.district}
                                                                        isMulti={false}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>}
                                                </Tab>
                                                <Tab eventKey="propertyValue" title="Itens">
                                                    <Row className="tab-item-container">{this.getListItens()}</Row>
                                                </Tab>
                                                {this.props.typeProp != 'D' &&
                                                    <Tab eventKey="property" title="Proprietários">
                                                        {this.getTable()}
                                                    </Tab>}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" style={{ marginTop: 20 }}>
                                            <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                                <Image className="imageSave"
                                                    src={salvar} />
                                                Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <ToastContainer />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {this.getModalProperty()}
                {this.getModalAddPerson()}
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }

    private setCity(id: number, value: string) {
        var json = this.state.json as any
        json['ccidade'] = id
        json['municipio'] = value

        this.setState({
            json: json
        })
    }

    private setTab(key: string) {
        this.setState({
            tabKey: key
        })
    }

    private getTable() {
        return (
            <Row className="tab-item-container">
                <Col xs="12" className="containerActions">
                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setShow(true)}>
                        <Image
                            className="iconButtonGeneric"
                            src={adicionar} />
                        Adicionar
                    </Button>
                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setEdit()}>
                        <Image
                            className="iconButtonGeneric"
                            src={editar} />
                        Editar
                    </Button>
                    <Button variant="danger" type="button" className="buttonGeneric colorRed" onClick={() => this.setDelete()}>
                        <Image
                            className="iconButtonGeneric"
                            src={deletar} />
                        Excluir
                    </Button>
                </Col>
                <Col xs="12">
                    <div className="tableData">
                        <Table responsive={true}>
                            <thead className="tableDataHead">
                                <tr>
                                    <th>Nome</th>
                                    <th>Principal</th>
                                </tr>
                            </thead>
                            <tbody className="tableDataBody">
                                {this.getContainerTable()}
                            </tbody>
                        </Table>
                    </div>
                    <div className="tableContainerNav">
                        <Container fluid={true}>
                            <Row>
                                <Col xs="12">
                                    <div style={{ height: 10 }}></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>)
    }

    private getContainerTable() {
        var container = new Array<any>()
        var array = this.state.arrayProperty

        array.forEach((item, index) => {
            container.push((
                <tr
                    key={`${index}`}
                    style={{ backgroundColor: this.changeColorLine(index) }}
                    onClick={() => this.selectLine(index)}>
                    <td>{item.nome}</td>
                    <td>{item.principal}</td>
                </tr>
            ))
        })

        return container
    }

    private selectLine(index: number) {
        this.setState({
            indexSelectProperty: index
        })
    }

    private changeColorLine(index: number): string {
        return this.state.indexSelectProperty == index ? '#eeeeee' : '#ffffff'
    }

    private setShow(isClearJSON: Boolean) {
        if (isClearJSON) {
            this.clearJSON()
        }

        var show = this.state.showFormProperty
        this.setState({
            showFormProperty: !show
        })
    }

    private setEdit() {
        var index = this.state.indexSelectProperty
        var array = this.state.arrayProperty
        if (index == -1) {
            return
        }

        if (array[index]) {
            var json = this.state.json as any
            json = Object.assign(json, array[index])
            json['indexSelected'] = index

            this.setState({ json: json, personEdit: array[index].sgeppessoa })
            this.setShow(false)
        }
    }

    private setDelete() {
        var index = this.state.indexSelectProperty
        var array = this.state.arrayProperty
        if (index == -1) {
            return
        }

        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    array.splice(index, 1)
                    this.setState({
                        arrayProperty: array,
                        indexSelectProperty: -1
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private getModalProperty() {
        var json = this.state.json as any
        return (
            <Modal show={this.state.showFormProperty}>
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{ fontSize: "20px", fontWeight: "bold" }}>Cadastro Proprietário</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                    id="sgepgrupopessoaparente"
                                    defaultValue={json['sgepgrupopessoaparente'] ? json['sgepgrupopessoaparente'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <InputModalSelectComponent
                                    id="sgeppessoa"
                                    keyValue="nome"
                                    label="Pessoa"
                                    required={true}
                                    placeholder="Procure"
                                    showAddButton={true}
                                    navigation={this.props.navigation}
                                    titleModal="Pessoas"
                                    table="geppessoas"
                                    valueDefault={json['nome']}
                                    getValue={(id: number, value: string) => this.setIdPerson(id, value)}
                                    tapAdd={() => this.setState({ showAddPerson: true })} />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Principal</Form.Label>
                                <Select
                                    id="principal"
                                    value={this.formEvent.onValueSelect('select', 'principal', this.state.main)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "principal")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.main}
                                    isMulti={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{ marginTop: 20 }}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.saveProperty()}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.setShow(false)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                    <ToastContainer />
                </Modal.Body>
            </Modal>
        )
    }

    private setIdPerson(id: number, value: string) {
        var json = this.state.json as any
        json['sgeppessoa'] = id
        json['nome'] = value

        this.setState({
            json: json
        })
    }

    private getModalAddPerson() {
        let viewModel = new PersonViewModel()
        return (
            <Modal show={this.state.showAddPerson} dialogClassName="modal-full-screen">
                <Modal.Body>
                    <PersonViewController
                        navigation={this.props.navigation}
                        delegate={this}
                        idPrimary={0}
                        viewModel={viewModel}
                        isShowHeader={false}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    private loadMain() {
        var array = Array<any>()
        array.push({ value: 'S', label: 'Sim', check: false })
        array.push({ value: 'N', label: 'Não', check: false })

        this.setState({
            main: array
        })
    }

    private saveProperty() {
        var json = this.state.json as any
        var array = this.state.arrayProperty as Array<any>

        if (!json['sgeppessoa'] || !json['principal']) {
            this.showFieldsEmpty('Todos os campos devem ser preenchidos!')
            return
        }

        if (this.state.personEdit != json['sgeppessoa'] && array.filter((x) => x['sgeppessoa'] == json['sgeppessoa']).length > 0) {
            this.showFieldsEmpty('Pessoa já vinculada!')
            return
        }

        var copyJSON = Object.assign({}, json)
        var jsonFinal = {} as any
        jsonFinal['sgepgrupopessoaparente'] = copyJSON['sgepgrupopessoaparente']
        jsonFinal['nome'] = copyJSON['nome']
        jsonFinal['sgeppessoa'] = copyJSON['sgeppessoa']
        jsonFinal['principal'] = copyJSON['principal']

        if (json['indexSelected'] >= 0) {
            array[json['indexSelected']] = jsonFinal
        } else {
            array.push(jsonFinal)
        }

        this.setState({
            arrayProperty: array,
            showFormProperty: false,
            personEdit: null
        })
    }

    private save() {
        var json = this.state.json as any
        if (json["anoinicial"] == undefined || json["anofinal"] == undefined || json['sgepdistrito'] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if (String(json["anoinicial"]).length != 10 || String(json["anofinal"]).length != 10) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        let yearInitial = moment(json['anoinicial'], 'DD/MM/YYYY')
        let yearFinal = moment(json['anofinal'], 'DD/MM/YYYY')

        if (yearInitial.isValid() == false || yearFinal.isValid() == false) {
            this.showFieldsEmpty("Intervalo de anos inválido!")
            return
        }

        if (yearInitial.isAfter(yearFinal)) {
            this.showFieldsEmpty("Intervalo de anos inválido!")
            return
        }

        for (var i = 0; i < this.state.listItens.length; i++) {
            var itemConf = this.state.listItens[i]
            if (this.props.typeProp !== 'D') {
                if (itemConf['obrigatorio'] == 'S') {
                    if (json[`item-${itemConf['sgeppropriedadeconfiguracao']}`] == null) {
                        this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                        return
                    }

                    if (json[`item-${itemConf['sgeppropriedadeconfiguracao']}`].length == 0) {
                        this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                        return
                    }
                }

                if (itemConf['tipo'] == 'D') {
                    if (json[`item-${itemConf['sgeppropriedadeconfiguracao']}`] != null) {
                        if (json[`item-${itemConf['sgeppropriedadeconfiguracao']}`].length != 0) {
                            var date = moment(json[`item-${itemConf['sgeppropriedadeconfiguracao']}`], "DD/MM/YYYY")
                            if (!date.isValid()) {
                                this.showFieldsEmpty("Data inválida!")
                                return
                            }
                        }
                    }
                }
            } else {
                if (itemConf['obrigatorio'] == 'S') {
                    if (json[`item-${itemConf['sgeppontodiversos']}`] == null) {
                        this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                        return
                    }

                    if (json[`item-${itemConf['sgeppontodiversos']}`].length == 0) {
                        this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
                        return
                    }
                }

                if (itemConf['tipo'] == 'D') {
                    if (!json[`item-${itemConf['sgeppontodiversos']}`]) {
                        var date = moment(json[`item-${itemConf['sgeppontodiversos']}`], "DD/MM/YYYY")
                        if (!date.isValid()) {
                            this.showFieldsEmpty("Data inválida!")
                            return
                        }
                    }
                }
            }
        }

        var itens = new Array<any>()
        // if (this.props.typeProp !== 'D') {
        var keys = Object.keys(json)
        keys.forEach((key) => {
            if (key.indexOf('item') != -1) {
                let itemId = key.substring(key.indexOf('-') + 1, key.length);
                itens.push({
                    key: itemId,
                    value: json[key]
                })
                // delete json[key]
            }
        })

        // } else {
        //     itens = this.state.listItens
        // json['tpprop'] = this.props.typeProp
        // }
        if (this.props.typeProp == 'D') {
            json['tpprop'] = this.props.typeProp
        }
        this.setState({
            isLoading: true
        })
        json['itens'] = itens
        json['copiarpropriedade'] = this.props.copyProp
        json['copiarsgeppropriedade'] = this.props.idPrimary
        json['proprietarios'] = this.state.arrayProperty
        this.props.viewModel.fetchSave(json).then((rs) => {
            if (rs['sgeppropriedade'] > 0) {
                json['sgeppropriedade'] = rs['sgeppropriedade']
            }

            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            this.setState({
                isLoading: false
            })

            if (e['Resultado'] == 'Invervalo de anos inválido!') {
                toast.error(e['Resultado'], {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                return
            }

            toast.error("Erro interno!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadTypeProperty() {
        var array = Array<any>()
        array.push({ value: "U", label: "Urbano", check: false })
        array.push({ value: "R", label: "Rural", check: false })

        this.setState({
            typeProperty: array
        })
    }

    private loadInputs() {
        var array = Array<any>()
        array.push({ value: "S", label: "Sim", check: false })
        array.push({ value: "N", label: "Não", check: false })

        this.setState({
            inputWater: array,
            inputEnergy: array
        })
    }

    private loadBlockFace() {
        var array = Array<any>()
        array.push({ value: "P", label: "Par", check: false })
        array.push({ value: "I", label: "Ímpar", check: false })
        array.push({ value: "N", label: "Nenhum", check: false })

        this.setState({
            blockFace: array
        })
    }

    private loadStruct() {
        var json = this.state.json as any
        json['sgeppropriedade'] = this.props.idPrimary

        this.setState({ isLoading: true })
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            if (this.props.copyProp) {
                delete json['sgeppropriedade']
            }

            var resultado = rs as Array<any>
            if (resultado[0]['anoinicial']) {
                resultado[0]['anoinicial'] = moment(resultado[0]['anoinicial'], 'YYYY-MM-DD').format('DD/MM/YYYY')
            }

            if (resultado[0]['anofinal']) {
                resultado[0]['anofinal'] = moment(resultado[0]['anofinal'], 'YYYY-MM-DD').format('DD/MM/YYYY')
            }

            if (resultado.length > 0) {
                if (this.props.copyProp) {
                    delete resultado[0]['sgeppropriedade']
                }

                this.setState({
                    isLoading: false,
                    arrayProperty: resultado[0]['proprietarios'],
                    json: resultado[0],
                    showButtonDelete: true
                })

                return
            }

            this.setState({ isLoading: false })
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    tapDimiss(navigation: any) {
        this.setState({
            showAddPerson: false
        })
    }

    private clearJSON() {
        var json = this.state.json as any
        delete json['sgepgrupopessoaparente']
        delete json['nome']
        delete json['sgeppessoa']
        delete json['principal']
        delete json['cgepgrauparentesco']
        delete json['indexSelected']

        this.setState({
            json: json
        })
    }

    private getListItens() {
        var json = this.state.json as any
        var arrayListItens = new Array<any>()
        var result = this.state.listItens as Array<any>
        result.forEach((item) => {
            var listItem = this.loadArraySelect(item['itens'])
            var isRequired = item['obrigatorio'] == 'S' ? 'isRequired' : ''
            if (this.props.typeProp !== 'D') {
                arrayListItens.push(
                    <Col xs="12" lg="4" className="bottom10">
                        <Form.Label className={isRequired}>{item.nome}</Form.Label>
                        {item.tipo == 'L' &&
                            <Select
                                id={`item-${item.sgeppropriedadeconfiguracao}`}
                                value={this.formEvent.onValueSelect('select', `item-${item.sgeppropriedadeconfiguracao}`, listItem)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, `item-${item.sgeppropriedadeconfiguracao}`)}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={listItem}
                                isMulti={false}
                            />}
                        {item.tipo == 'V' &&
                            <NumberFormat
                                id={`item-${item.sgeppropriedadeconfiguracao}`}
                                className="form-control"
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                prefix="R$"
                                value={json[`item-${item.sgeppropriedadeconfiguracao}`] ? json[`item-${item.sgeppropriedadeconfiguracao}`] : ''}
                                onValueChange={(values) => this.formEvent.onChange("money", values, `item-${item.sgeppropriedadeconfiguracao}`)}
                            />}
                        {item.tipo != 'L' && item.tipo != 'V' &&
                            <Form.Control
                                id={`item-${item.sgeppropriedadeconfiguracao}`}
                                defaultValue={json[`item-${item.sgeppropriedadeconfiguracao}`] ? json[`item-${item.sgeppropriedadeconfiguracao}`] : ''}
                                onChange={(e: any) => this.formEvent.onChange(this.getInput(item.tipo), e.target)}
                            />}
                    </Col>
                )
            } else {
                arrayListItens.push(
                    <Col xs="12" lg="4" className="bottom10">
                        <Form.Label className={isRequired}>{item.nome}</Form.Label>
                        {item.tipo == 'L' &&
                            <Select
                                id={`item-${item.sgeppontodiversos}`}
                                value={this.formEvent.onValueSelect('select', `item-${item.sgeppontodiversos}`, listItem)}
                                onChange={(e: any) => this.formEvent.onChange("select", e, `item-${item.sgeppontodiversos}`)}
                                closeMenuOnSelect={true}
                                placeholder={"Selecione"}
                                options={listItem}
                                isMulti={false}
                            />}
                        {item.tipo == 'V' &&
                            <NumberFormat
                                id={`item-${item.sgeppontodiversos}`}
                                className="form-control"
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                prefix="R$"
                                value={json[`item-${item.sgeppontodiversos}`] ? json[`item-${item.sgeppontodiversos}`] : ''}
                                onValueChange={(values) => this.formEvent.onChange("money", values, `item-${item.sgeppontodiversos}`)}
                            />}
                        {item.tipo != 'L' && item.tipo != 'V' &&
                            <Form.Control
                                id={`item-${item.sgeppontodiversos}`}
                                defaultValue={json[`item-${item.sgeppontodiversos}`] ? json[`item-${item.sgeppontodiversos}`] : ''}
                                onChange={(e: any) => this.formEvent.onChange(this.getInput(item.tipo), e.target)}
                            />}
                    </Col>
                )
            }
        })

        return arrayListItens
    }

    private getInput(item: string): TypeComponent {
        switch (item) {
            case "A":
                return "varchar"

            case "D":
                return "date"

            case "N":
                return "double"

            case "T":
                return "varchar"

            default:
                return "varchar"
        }
    }

    private loadArraySelect(itens: Array<any>): Array<any> {
        var array = Array<any>()

        for (var i = 0; i < itens.length; i++) {
            var item = itens[i]
            if (this.props.typeProp !== 'D') {
                array.push({ value: item.sgeppropriedadeconfiguracaoitem, label: item.item, check: false })
            } else {
                array.push({ value: item.sgeppontodiversosconfiguracaoitem, label: item.item, check: false })
            }
        }
        return array
    }

    private loadItens() {
        this.props.viewModel.fetchListItens(this.props.typeProp, (this.state.json as any).sgeppontos).then((rs) => {
            var resultado = rs as Array<any>
            if (resultado.length > 0) {
                this.setState({
                    listItens: resultado
                })
            }
        })
    }

    private loadSelect() {
        this.props.viewModel.fetchSelectProperty().then((rs) => {
            var json = rs.length > 0 ? rs[0] : {} as any
            var keys = Object.keys(json)

            for (var i = 0; i < keys.length; i++) {
                var array = Array<any>()
                var result = json[keys[i]] as Array<any>

                for (var a = 0; a < result.length; a++) {
                    var item = result[a]
                    array.push({ value: item.id, label: item.value, check: false })
                }

                switch (keys[i]) {
                    case "gepdistrito":
                        this.setState({ district: array })
                        break;
                    case "gepformaobtencao":
                        this.setState({ formObtaining: array })
                        break;
                    case "geplimitacaolote":
                        this.setState({ batchLimitation: array })
                        break;
                    case "geppasseiopublico":
                        this.setState({ publicRide: array })
                        break;
                    case "geppavimentacao":
                        this.setState({ paving: array })
                        break;
                    case "geppedologia":
                        this.setState({ pedology: array })
                        break;
                    case "geppiscina":
                        this.setState({ pool: array })
                        break;
                    case "gepproptpacesso":
                        this.setState({ acessTypeProperty: array })
                        break;
                    case "gepsituacaopropriedade":
                        this.setState({ situation: array })
                        break;
                    case "gepsituacaoproprural":
                        this.setState({ ruralPropertySituation: array })
                        break;
                    case "gepsituacaoquadra":
                        this.setState({ courtSituation: array })
                        break;
                    case "geptipoedificacao":
                        this.setState({ buildingType: array })
                        break;
                    case "geptipopatrimonio":
                        this.setState({ typeHeritage: array })
                        break;
                    case "geptopografia":
                        this.setState({ topography: array })
                        break;
                    case "gepzoneamento":
                        this.setState({ zoning: array })
                        break;
                    case "gepocupacaolote":
                        this.setState({ lotOccupation: array })
                        break;
                    case "geplitigio":
                        this.setState({ litigation: array })
                        break;
                    case "geplocalizacaorural":
                        this.setState({ ruralLocation: array })
                        break;
                }
            }
        })
    }
}