import React from 'react';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { SeveralPointConfigViewController, SeveralPointConfigViewControllerDelegate } from '../../Screen/SeveralPointConfig/SeveralPointConfigViewController';
import { SeveralPointConfigViewModel } from '../../Screen/SeveralPointConfig/SeveralPointConfigViewModel';
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";

export class SeveralPointConfigCoordinator implements IndexCoordinatorDelegate, TableGenericViewControllerDelegate, SeveralPointConfigViewControllerDelegate {
    
    getRoute(): JSX.Element[] {
        return [
            this.routeTable(),
            this.routeForm()
        ]
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geppontodiversos")
        return (<ProtectedRoute requiredPermissions={['ponto_diverso.config.*']} path="/:base/ponto-diverso-configuracao" exact={true} component={(props: any) => {
            return (<TableGenericViewController 
                permissions={{
                    add: 'ponto_diverso.config.adicionar',
                    edit: 'ponto_diverso.config.editar',
                    delete: 'ponto_diverso.config.excluir',
                }}
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                title={"Configuração Ponto Diverso"}
            />)
        }}/>)
    }

    private routeForm(): JSX.Element {
        var viewModel = new SeveralPointConfigViewModel()
        return (<ProtectedRoute requiredPermissions={['ponto_diverso.config.adicionar', 'ponto_diverso.config.editar']} path="/:base/ponto-diverso-configuracao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<SeveralPointConfigViewController 
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
                idPrimary={idPk}
                title={"Configuração Ponto Diverso"}
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
    
    add(navigation: any, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geppontodiversos") {
            navigation.history.push(`ponto-diverso-configuracao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if(viewModel.getTable() == "geppontodiversos") {
            navigation.history.push(`ponto-diverso-configuracao/cad/${idSelection}`);
            return;
        }
    }

}