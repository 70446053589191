import { Request } from '../../Servidor/Request';

export class SeveralPointsConfigItem {
    private sgeppontodiversositem: number;
    private item: string;
    private valor: number;


    constructor(sgeppontodiversositem: number, item: string, valor: number) {
        this.sgeppontodiversositem = sgeppontodiversositem;
        this.item = item;
        this.valor = valor;
    }


    /**
     * Getter sgeppontodiversositem
     * @return {number}
     */
    public getSeppontodiversositem(): number {
        return this.sgeppontodiversositem;
    }

    /**
     * Getter item
     * @return {string}
     */
    public getItem(): string {
        return this.item;
    }

    /**
     * Getter valor
     * @return {number}
     */
    public getValor(): number {
        return this.valor;
    }

}

export class SeveralPointConfigRequest {
    private sgeppontodiversos: number;
    private nome: string;
    private tipo: string;
    private obrigatorio: string;
    private ordem: number;
    private itens: Array<SeveralPointsConfigItem>;
    private sgeptipoponto: number;


    constructor(sgeppontodiversos: number, nome: string, tipo: string, obrigatorio: string, ordem: number, itens: Array<SeveralPointsConfigItem>, sgeptipoponto: number) {
        this.sgeppontodiversos = sgeppontodiversos
        this.nome = nome
        this.tipo = tipo
        this.obrigatorio = obrigatorio
        this.ordem = ordem
        this.itens = itens
        this.sgeptipoponto = sgeptipoponto
    }


    /**
     * Getter sgeppontodiversos
     * @return {number}
     */
    public getSgeppropriedadeconfiguracao(): number {
        return this.sgeppontodiversos;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }


    /**
     * Getter $tipo
     * @return {string}
     */
    public get $tipo(): string {
        return this.tipo;
    }


    /**
     * Getter $obrigatorio
     * @return {string}
     */
    public get $obrigatorio(): string {
        return this.obrigatorio;
    }


    /**
     * Getter ordem
     * @return {number}
     */
    public getOrdem(): number {
        return this.ordem;
    }

    /**
     * Getter itens
     * @return {Array<SeveralPointConfigItem>}
     */
    public getItens(): Array<SeveralPointsConfigItem> {
        return this.itens;
    }

    /**
     * Getter $sgeptipoponto
     * @return {string}
     */
    public get $sgeptipoponto(): number {
        return this.sgeptipoponto;
    }
}

export class SeveralPointConfigStructRequest {
    private sgeppontodiversos: number;

    constructor(sgeppontodiversos: number) {
        this.sgeppontodiversos = sgeppontodiversos;
    }



    /**
     * Getter sgeppontodiversos
     * @return {number}
     */
    public getSgeppropriedadeconfiguracao(): number {
        return this.sgeppontodiversos;
    }


}


export class PointTypeSelectResponse {
    private id: number;
    private value: string;

    constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
    }
    /**
     * Getter id
     * @return {number}
     */
    public getId(): number {
        return this.id;
    }

    /**
     * Getter value
     * @return {string}
     */
    public getValue(): string {
        return this.value;
    }

}

export class SeveralPointConfigInteractor {
    public fetchSave(request: SeveralPointConfigRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("ponto-diverso/configuracao/salvar", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Status == "Sucesso")
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: SeveralPointConfigStructRequest): PromiseLike<SeveralPointConfigRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("ponto-diverso/configuracao/buscar", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }


    public fetchSelectPointType(): PromiseLike<PointTypeSelectResponse> {
        return new Promise((res, rej) => {
            return new Request().requestPost("formulario/tipo-ponto/buscar-select", {}).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}