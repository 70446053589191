import React from 'react';
import { Row, Col, Container, Image, Button, FormLabel, Table } from 'react-bootstrap';
import { MdInfo, MdHome, MdLocationOn, MdLocalPostOffice, MdMap, MdImage, MdFileDownload } from 'react-icons/md';
import { MdAdd} from 'react-icons/md'
import { MdKeyboardArrowDown} from 'react-icons/md'
import { MdKeyboardArrowRight} from 'react-icons/md'
import { MdEdit} from 'react-icons/md'
import { MdDelete} from 'react-icons/md'
import { MdPhotoCamera} from 'react-icons/md'
import { MdContentCut} from 'react-icons/md'
import { MdRotateLeft} from 'react-icons/md'
import { FaStreetView } from "react-icons/fa";

import notFound from '../Imagens/image-not-found.jpg';
import flechaDireita from "../Imagens/arrow-right-24-blue.png";
import "../Estilo/Mapa.css";
import { Request } from '../Servidor/Request';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from './LoaderComponente';
import { confirmAlert } from 'react-confirm-alert';

interface MyProps {
    sshape: number,
    typeShape: string,
    attributes: any,
    startEdit: boolean,
    clickAction: (action: Action, sshape: number) => void
    clickLink: (link: string) => void
    close: () => void
}

type TypeContainer = "attribute" | "visit" | "properties" | "building" | "owner" | "resident" | "technicalRegistration" | "adminProcess"
export type Action = "addPoint" | "editPoint" | "deletePoint" | "visitPoint" | "editShape" | "attributesShape" | "deleteShape" | "property" | "address" | "adminProcess" | "cutShape" | "moveShape" | "editShapeCoordinator" | "imagem" | "export" | "streetView"
export class ShapeComponente extends React.Component<MyProps> {

    private sshape: number
    constructor(props: any) {
        super(props)
        this.sshape = 0
    }

    state = {
        src: "",
        isLoading: false,
        sgeppontos: 0,
        simagem: 0,
        showAttributes: false,
        showVisit: false,
        showProperties: false,
        showBuilding: false,
        showOwner: false,
        showResidents: false,
        showTechnicalRegistration: false,
        showAdminProcess: false,
        visit: [],
        attributes: [],
        properties: [],
        building: [],
        persons: [],
        technicalRegistration: [],
        adminProcess: []
    }

    componentDidMount() {
        this.sshape = this.props.sshape
        this.loadInfoPonto()
    }

    componentDidUpdate() {
        if(this.sshape != this.props.sshape) {
            this.sshape = this.props.sshape
            this.loadInfoPonto()
        }
    }

    render() {
        return (
            <div className="infoShape" style={{width: window.innerWidth > 400 ? "400px" : `${window.innerWidth}px`}}>
                <Container fluid={true}>
                    <Row>
                        <Image
                        onClick={() => this.props.close()}
                        className="infoShapeArrowRight"
                        fluid={true}
                        src={flechaDireita}/>
                    </Row>
                    <Row className="container-image-main">
                        <Image
                        className="image-main"
                        style={{border: "1px solid #cccccc", cursor: "pointer"}}
                        fluid={true}
                        src={this.state.src == null || this.state.src.length == 0 ? notFound : this.state.src}
                        onClick={() => this.onChangeImage()}
                        onError={ e => { e.currentTarget.src = notFound}} />
                    </Row>
                    {!this.props.startEdit &&
                    <div>
                        <Row className="infoShapeRowButton">
                            {this.props.typeShape == "Point" && this.state.sgeppontos == 0 &&
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("addPoint", this.props.sshape)}>
                                    <MdAdd size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Criar</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            }
                            {this.props.typeShape == "Point" && this.state.sgeppontos > 0 &&
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("editPoint", this.props.sshape)}>
                                    <MdEdit size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Editar</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            }
                            {this.props.typeShape == "Point" && this.state.sgeppontos > 0 &&
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("deletePoint", this.props.sshape)}>
                                    <MdDelete size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Remover</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            }
                            {this.props.typeShape == "Point" && 
                            (this.props.attributes['tipo'] == 'U' || this.props.attributes['tipo'] == 'R') && 
                            this.state.sgeppontos > 0 &&
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("address", this.props.sshape)}>
                                    <MdLocationOn size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Endereço</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            }
                            {this.props.typeShape == "Point" && this.state.sgeppontos > 0 &&
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("property", this.props.sshape)}>
                                    <MdHome size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">{this.props.attributes['tipo'] == 'D' ? 'Cadastro' : 'Propriedade'}</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            }
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("visitPoint", this.props.sshape)}>
                                    <MdInfo size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Visita</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("adminProcess", this.props.sshape)}>
                                    <MdLocalPostOffice size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Processo Administrativo</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("imagem", this.state.sgeppontos)}>
                                    <MdImage size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Imagens</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("export", this.state.sgeppontos)}>
                                    <MdFileDownload size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Exportar</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("streetView", this.state.sgeppontos)}>
                                    <FaStreetView size={25} className="infoShapeIcon" />
                                </Button>
                                <Row className="infoShapeRowButton">
                                    <Col xs="12">
                                        <FormLabel className="infoShapeLabel">Street View</FormLabel>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>}
                    {this.props.startEdit &&
                    <div>
                        <Row className="infoShapeRowButton">
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("editShape", this.props.sshape)}>
                                    <MdEdit size={25} className="infoShapeIcon" />
                                </Button>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("editShapeCoordinator", this.props.sshape)}>
                                    <MdMap size={25} className="infoShapeIcon" />
                                </Button>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("cutShape", this.props.sshape)}>
                                    <MdContentCut size={25} className="infoShapeIcon" />
                                </Button>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("moveShape", this.props.sshape)}>
                                    <MdRotateLeft size={25} className="infoShapeIcon" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="infoShapeRowButton">
                            <Col xs="3">
                                <FormLabel className="infoShapeLabel">Editar</FormLabel>
                            </Col>
                            <Col xs="3">
                                <FormLabel className="infoShapeLabel">Editar Coordenadas</FormLabel>
                            </Col>
                            <Col xs="3">
                                <FormLabel className="infoShapeLabel">Recortar</FormLabel>
                            </Col>
                            <Col xs="3">
                                <FormLabel className="infoShapeLabel">Mover</FormLabel>
                            </Col>
                        </Row>
                        <Row className="infoShapeRowButton">
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("deleteShape", this.props.sshape)}>
                                    <MdDelete size={25} className="infoShapeIcon" />
                                </Button>
                            </Col>
                            <Col xs="3">
                                <Button className="infoShapeButton" type="button" onClick={() => this.props.clickAction("attributesShape", this.props.sshape)}>
                                    <MdInfo size={25} className="infoShapeIcon" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="infoShapeRowButton">
                            <Col xs="3">
                                <FormLabel className="infoShapeLabel">Deletar</FormLabel>
                            </Col>
                            <Col xs="3">
                                <FormLabel className="infoShapeLabel">Atributos</FormLabel>
                            </Col>
                        </Row>
                    </div>}
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("attribute")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Atributos</FormLabel>
                            {this.getArrowContainer(this.state.showAttributes)}
                        </Button>
                    </Row>
                    {this.state.showAttributes &&
                    <Row>{this.renderAttributes()}</Row>}
                    {!this.props.startEdit && this.props.typeShape == "Point" && this.state.sgeppontos > 0 && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("technicalRegistration")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Cadastro Técnico</FormLabel>
                            {this.getArrowContainer(this.state.showTechnicalRegistration)}
                        </Button>
                    </Row>}
                    {this.state.showTechnicalRegistration &&
                    <Row>{this.renderTechnicalRegistration()}</Row>}
                    {!this.props.startEdit && this.props.typeShape == "Point" && this.state.sgeppontos > 0 && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("building")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Edificações</FormLabel>
                            {this.getArrowContainer(this.state.showBuilding)}
                        </Button>
                    </Row>}
                    {this.state.showBuilding &&
                    <Row>{this.renderBuilding()}</Row>}
                    {!this.props.startEdit && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("adminProcess")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Processos Administrativo</FormLabel>
                            {this.getArrowContainer(this.state.showAdminProcess)}
                        </Button>
                    </Row>}
                    {this.state.showAdminProcess &&
                    <Row>{this.renderAdminProcess()}</Row>}
                    {!this.props.startEdit && this.props.typeShape == "Point" && this.state.sgeppontos > 0 && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("properties")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Propriedades</FormLabel>
                            {this.getArrowContainer(this.state.showProperties)}
                        </Button>
                    </Row>}
                    {this.state.showProperties &&
                    <Row>{this.renderProperties()}</Row>}
                    {!this.props.startEdit && this.props.typeShape == "Point" && this.state.sgeppontos > 0 && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("owner")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Proprietários</FormLabel>
                            {this.getArrowContainer(this.state.showOwner)}
                        </Button>
                    </Row>}
                    {this.state.showOwner &&
                    <Row>{this.renderPersons('P', 'owner')}</Row>}
                    {!this.props.startEdit && this.props.typeShape == "Point" && this.state.sgeppontos > 0 && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("resident")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Residentes</FormLabel>
                            {this.getArrowContainer(this.state.showResidents)}
                        </Button>
                    </Row>}
                    {this.state.showResidents &&
                    <Row>{this.renderPersons('R', 'resident')}</Row>}
                    {!this.props.startEdit && 
                    <Row>
                        <Button className="infoShapeButtonInformation" type="button" onClick={() => this.showContainer("visit")}>
                            <FormLabel className="infoShapeButtonInformationLabel">Visitas</FormLabel>
                            {this.getArrowContainer(this.state.showVisit)}
                        </Button>
                    </Row>}
                    {this.state.showVisit &&
                    <Row>{this.renderVisit()}</Row>}
                    <ToastContainer />
                </Container>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private showContainer(type: TypeContainer) {
        switch (type) {
            case "attribute":
                this.setState({
                    showAttributes: !this.state.showAttributes
                })
                break;
            case "building":
                this.setState({
                    showBuilding: !this.state.showBuilding
                })
                break;
            case "adminProcess":
                this.setState({
                    showAdminProcess: !this.state.showAdminProcess
                })
                break;
            case "properties":
                this.setState({
                    showProperties: !this.state.showProperties
                })
                break;
            case "owner":
                this.setState({
                    showOwner: !this.state.showOwner
                })
                break;
            case "resident":
                this.setState({
                    showResidents: !this.state.showResidents
                })
                break;
            case "technicalRegistration":
                this.setState({
                    showTechnicalRegistration: !this.state.showTechnicalRegistration
                })
                break;
            case "visit":
                this.setState({
                    showVisit: !this.state.showVisit
                })
        }
    }

    private getArrowContainer(isActive: boolean) {
        if(isActive) {
            return (
                <MdKeyboardArrowDown size={25} className="infoShapeButtonInformationIcon" />
            )
        }

        return (
            <MdKeyboardArrowRight size={25} className="infoShapeButtonInformationIcon" />
        )
    }

    private renderAttributes() {
        var attributes = this.state.attributes as Array<any>
        var list = attributes.filter((item: any) => item['key'] != 'shape' && item['key'] != 'scamada' && item['key'] != 'sshapetemp')
        var arrayLines = new Array<any>();

        list.forEach((item: any, index: number) => {
            arrayLines.push((
                <tr key={`attribute-${index}`}>
                    <th>{item.key}</th>
                    <td>{item.value}</td>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Atributo</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private renderVisit() {
        var arrayLines = new Array<any>()
        this.state.visit.forEach((item: any, index: number) => {
            arrayLines.push(( 
                <tr key={`visit-${index}`}>
                    <th>{item.data}</th>
                    <td>{item.profissional}</td>
                    <th>
                        <Button className="infoShapeAcao" type="button" onClick={() => this.props.clickLink(item.link)}>
                            <MdEdit size={15} className="infoShapeAcaoIcon" />
                        </Button>
                    </th>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Profissional</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private renderAdminProcess() {
        var arrayLines = new Array<any>()
        this.state.adminProcess.forEach((item: any, index: number) => {
            arrayLines.push(( 
                <tr key={`adminProcess-${index}`}>
                    <th>{item.data}</th>
                    <td>{item.nome}</td>
                    <th>
                        <Button className="infoShapeAcao" type="button" onClick={() => this.props.clickLink(item.link)}>
                            <MdEdit size={15} className="infoShapeAcaoIcon" />
                        </Button>
                    </th>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Profissional</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private renderProperties() {
        var arrayLines = new Array<any>()
        this.state.properties.forEach((item: any, index: number) => {
            arrayLines.push(( 
                <tr key={`properties-${index}`}>
                    <th>{item.anoinicial + " - " + item.anofinal}</th>
                    <th>{item.inscricao}</th>
                    <th>
                        <Button className="infoShapeAcao" type="button" onClick={() => this.props.clickLink(item.link)}>
                            <MdEdit size={15} className="infoShapeAcaoIcon" />
                        </Button>
                    </th>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Data Cadastro</th>
                        <th>Inscrição</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private renderTechnicalRegistration() {
        var arrayLines = new Array<any>()
        this.state.technicalRegistration.forEach((item: any, index: number) => {
            arrayLines.push(( 
                <tr key={`technicalRegistration-${index}`}>
                    <th>{item.datainicial + " - " + item.datafinal}</th>
                    <th>{item.inscricao}</th>
                    <th>
                        <Button className="infoShapeAcao" type="button" onClick={() => this.props.clickLink(item.link)}>
                            <MdEdit size={15} className="infoShapeAcaoIcon" />
                        </Button>
                    </th>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Data Cadastro</th>
                        <th>Inscrição</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private renderBuilding() {
        var arrayLines = new Array<any>()
        this.state.building.forEach((item: any, index: number) => {
            arrayLines.push(( 
                <tr key={`building-${index}`}>
                    <th>{item.id}</th>
                    <th>{item.inscricao}</th>
                    <th>
                        <Button className="infoShapeAcao" type="button" onClick={() => this.props.clickLink(item.link)}>
                            <MdEdit size={15} className="infoShapeAcaoIcon" />
                        </Button>
                    </th>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Identificador</th>
                        <th>Inscrição</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private renderPersons(type: string, key: string) {
        var arrayLines = new Array<any>()
        var list = this.state.persons.filter((x: any) => x['tipo'] == type)
        list.forEach((item: any, index: number) => {
            arrayLines.push(( 
                <tr key={`${key}-${index}`}>
                    <th>{item.nome}</th>
                    {type == 'R' && <th>{item.parentesco}</th>}
                    <th>{item.principal}</th>
                    <th>
                        <Button className="infoShapeAcao" type="button" onClick={() => this.props.clickLink(item.link)}>
                            <MdEdit size={15} className="infoShapeAcaoIcon" />
                        </Button>
                    </th>
                </tr>
            ))
        })

        return (
            <Table responsive={true} className="infoShapeTableAttributes">
                <thead>
                    <tr>
                        <th>Nome</th>
                        {type == 'R' && <th>Parentesco</th>}
                        <th>Principal</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>{arrayLines}</tbody>
            </Table>
        )
    }

    private onChangeImage() {
        if(this.state.sgeppontos == 0) {
            return 
        }

        var input = document.createElement('input');
        input.type = 'file';
        input.multiple = false;
        input.click();
        input.onchange = (e: any) => {
            this.setState({isLoading: true})
            var json = {
                sgeppontos: this.state.sgeppontos,
                dataimagem: new Date()
            }

            var formData = new FormData();
            let files    = e.target.files;
            for (var i = 0; i < files.length; i++) {
                formData.append(`images[${i}]`, files[i])
            }

            formData.append('body', JSON.stringify(json));
            new Request().requestFile('imagens/importar-foto-ponto', formData).then((rs) => {
                this.setState({
                    isLoading: false,
                    src: rs.result.caminhohttps
                })
            }, e => {
                toast.error(JSON.stringify(e), {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        }
    }

    private removeImage() {
        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja remover a imagem?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    this.setState({isLoading: true})
                    var json = {
                        simagem: this.state.simagem
                    }
                    
                    new Request().requestPost('imagens/remover-foto-ponto', json).then((rs) => {
                        this.setState({
                            isLoading: false,
                            src: ""
                        })
                    }, e => {
                        toast.error(JSON.stringify(e), {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private loadInfoPonto() {
        this.setState({isLoading: true})
        new Request().requestPost('formulario/ponto/buscar', {sshape: this.sshape}).then((rs) => {
            var resultado = rs.Resultado as Array<any> 
            if(resultado.length > 0) {
                let item = resultado[0]
                if(item['sgeppontos']) {
                    this.setState({
                        isLoading: false,
                        src: item['caminhoimagem'],
                        sgeppontos: parseInt(item['sgeppontos']),
                        simagem: item['simagem'],
                        visit: rs['Visitas'].Resultado,
                        attributes: rs['Atributos'].Resultado,
                        properties: rs['Propriedades'].Resultado,
                        building: rs['Edificacoes'].Resultado,
                        persons: rs['Pessoas'].Resultado,
                        technicalRegistration: rs['Cadastros'].Resultado,
                        adminProcess: rs['ProcessosAdms'].Resultado
                    })
                    return
                }

                this.setState({
                    isLoading: false,
                    src: item['caminhoimagem'],
                    sgeppontos: 0,
                    simagem: item['simagem'],
                    visit: rs['Visitas'].Resultado,
                    attributes: rs['Atributos'].Resultado,
                    properties: rs['Propriedades'].Resultado,
                    building: rs['Edificacoes'].Resultado,
                    persons: rs['Pessoas'].Resultado,
                    technicalRegistration: rs['Cadastros'].Resultado,
                    adminProcess: rs['ProcessosAdms'].Resultado
                })

                return
            }

            this.setState({
                isLoading: false,
                src: "",
                sgeppontos: 0,
                simagem: 0,
                visit: rs['Visitas'].Resultado,
                attributes: rs['Atributos'].Resultado,
                properties: rs['Propriedades'].Resultado,
                building: rs['Edificacoes'].Resultado,
                persons: rs['Pessoas'].Resultado,
                technicalRegistration: rs['Cadastros'].Resultado,
                adminProcess: rs['ProcessosAdms'].Resultado
            })
        }, e => {
            this.setState({
                isLoading: false,
                src: "",
                sgeppontos: 0
            })
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}