import React from 'react'
import { SeveralPointConfigViewModel } from './SeveralPointConfigViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png';
import salvar from '../../Imagens/save-16.png';
import adicionar from '../../Imagens/add-16.png';
import editar from '../../Imagens/edit-16.png';
import deletar from '../../Imagens/delete-16.png';

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { confirmAlert } from 'react-confirm-alert';
import { GlobalViewModel } from '../Global/GlobalViewModel';

interface MyProps {
    navigation: any
    delegate: SeveralPointConfigViewControllerDelegate
    viewModel: SeveralPointConfigViewModel
    idPrimary: number
    title: string
}

export interface SeveralPointConfigViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class SeveralPointConfigViewController extends React.Component<MyProps> {

    private globalViewModel = new GlobalViewModel()
    private formEvent: FormEvent
    state = {
        isLoading: false,
        showFormItem: false,
        json: {},
        arrayItens: new Array<any>(),
        indexSelectItem: -1,
        typeData: new Array<any>(),
        requiredData: new Array<any>(),
        pointType: new Array<any>()
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadTypeData()
        this.loadPointType()
        this.loadRequiredData()
        if (this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>{this.props.title}</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row className='px-2'>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                        id="sgeppontodiversos"
                                        defaultValue={json['sgeppontodiversos'] ? json['sgeppontodiversos'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                        id="nome"
                                        defaultValue={json['nome'] ? json['nome'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                        placeholder="Digite o nome"
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Tipo</Form.Label>
                                    <Select
                                        id="tipo"
                                        value={this.formEvent.onValueSelect('select', 'tipo', this.state.typeData)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "tipo")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.typeData}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Tipo ponto</Form.Label>
                                    <Select
                                        id="sgeptipoponto"
                                        value={this.formEvent.onValueSelect('select', 'sgeptipoponto', this.state.pointType)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptipoponto")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.pointType}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Obrigatório</Form.Label>
                                    <Select
                                        id="obrigatorio"
                                        value={this.formEvent.onValueSelect('select', 'obrigatorio', this.state.requiredData)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "obrigatorio")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.requiredData}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Ordem</Form.Label>
                                    <Form.Control
                                        id="ordem"
                                        defaultValue={json['ordem'] ? json['ordem'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                        placeholder="Digite a ordem"
                                    />
                                </Col>
                            </Row>
                            {(this.state.json as any)["tipo"] == "L" && <Row>
                                <Col xs="12" className="containerActions">
                                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setShow(true)}>
                                        <Image
                                            className="iconButtonGeneric"
                                            src={adicionar} />
                                        Adicionar
                                    </Button>
                                    <Button variant="info" type="button" className="buttonGeneric colorGeneric" onClick={() => this.setEdit()}>
                                        <Image
                                            className="iconButtonGeneric"
                                            src={editar} />
                                        Editar
                                    </Button>
                                    <Button variant="danger" type="button" className="buttonGeneric colorRed" onClick={() => this.setDelete()}>
                                        <Image
                                            className="iconButtonGeneric"
                                            src={deletar} />
                                        Excluir
                                    </Button>
                                </Col>
                                <Col xs="12">
                                    <div className="tableData">
                                        <Table responsive={true}>
                                            <thead className="tableDataHead">
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableDataBody">
                                                {this.getContainerTable()}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="tableContainerNav">
                                        <Container fluid={true}>
                                            <Row>
                                                <Col xs="12">
                                                    <div style={{ height: 10 }}></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>
                            </Row>}
                            <Row>
                                <Col xs="12" style={{ marginTop: 20 }}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                            src={salvar} />
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                    {this.getModal()}
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }

    private loadTypeData() {
        var array = Array<any>()
        array.push({ value: "A", label: "Alfanumérico", check: false })
        array.push({ value: "D", label: "Data", check: false })
        array.push({ value: "L", label: "Lista", check: false })
        array.push({ value: "N", label: "Numérico", check: false })
        array.push({ value: "T", label: "Texto", check: false })
        array.push({ value: "V", label: "Valor Monetário", check: false })

        this.setState({
            typeData: array
        })
    }

    private loadPointType() {
        this.globalViewModel.fetchDataToSelectInput('geptipoponto', 'sgeptipoponto').then((response) => {
            if (response.length) {
                this.setState({
                    pointType: response
                })
            }
        })
    }

    private loadRequiredData() {
        var array = Array<any>()
        array.push({ value: "S", label: "Sim", check: false })
        array.push({ value: "N", label: "Não", check: false })

        this.setState({
            requiredData: array
        })
    }

    private getContainerTable() {
        var container = new Array<any>()
        var array = this.state.arrayItens
        array.forEach((item, index) => {
            container.push((
                <tr
                    key={`${index}`}
                    style={{ backgroundColor: this.changeColorLine(index) }}
                    onClick={() => this.selectLine(index)}>
                    <td>{item.item}</td>
                    <td>{item.valor}</td>
                </tr>
            ))
        })

        return container
    }

    private selectLine(index: number) {
        this.setState({
            indexSelectItem: index
        })
    }

    private changeColorLine(index: number): string {
        return this.state.indexSelectItem == index ? '#eeeeee' : '#ffffff'
    }

    private setShow(isClearJSON: Boolean) {
        var json = this.state.json as any

        if (isClearJSON) {
            this.clearJSON()
        }

        var showFormItem = this.state.showFormItem
        this.setState({
            showFormItem: !showFormItem
        })
    }

    private setEdit() {
        var index = this.state.indexSelectItem
        var array = this.state.arrayItens
        if (index == -1) {
            return
        }

        if (array[index]) {
            var json = this.state.json as any
            json = Object.assign(json, array[index])
            json['indexSelected'] = index

            this.setState({ json: json })
            this.setShow(false)
        }
    }

    private setDelete() {
        var index = this.state.indexSelectItem
        var array = this.state.arrayItens
        if (index == -1) {
            return
        }

        confirmAlert({
            title: 'Atenção!',
            message: 'Deseja excluir esse registro?',
            buttons: [{
                label: 'Sim',
                onClick: () => {
                    array.splice(index, 1)
                    this.setState({
                        arrayItens: array,
                        indexSelectItem: -1
                    })
                }
            },
            {
                label: 'Não',
                onClick: () => {

                }
            }]
        })
    }

    private getModal() {
        var json = this.state.json as any
        return (
            <Modal show={this.state.showFormItem}>
                <Modal.Body>
                    <Container fluid={true}>
                        <Row>
                            <Col xs="12">
                                <Form.Label style={{ fontSize: "20px", fontWeight: "bold" }}>Cadastro Item</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="bottom10 hide">
                                <Form.Label>Sequencial</Form.Label>
                                <Form.Control
                                    id="sgeppontodiversosconfiguracaoitem"
                                    defaultValue={json['sgeppontodiversosconfiguracaoitem'] ? json['sgeppontodiversosconfiguracaoitem'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Item</Form.Label>
                                <Form.Control
                                    id="item"
                                    defaultValue={json['item'] ? json['item'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                />
                            </Col>
                            <Col xs="12" className="bottom10">
                                <Form.Label className="isRequired">Valor</Form.Label>
                                <Form.Control
                                    id="valor"
                                    defaultValue={json['valor'] ? json['valor'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" style={{ marginTop: 20 }}>
                                <Button className="buttonSave" type="button" variant="primary" onClick={() => this.saveItem()}>
                                    Salvar
                                </Button>
                                <Button className="buttonCancel" variant="link" onClick={() => this.setShow(false)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    private saveItem() {
        var json = this.state.json as any
        var array = this.state.arrayItens as Array<any>
        var item = json["item"]

        if (!item || !json.valor) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        var copyJSON = Object.assign({}, json)
        var jsonFinal = {} as any
        jsonFinal['sgeppontodiversos'] = copyJSON['sgeppontodiversos']
        jsonFinal['item'] = copyJSON['item']
        jsonFinal['valor'] = copyJSON['valor']

        if (json['indexSelected'] >= 0) {
            array[json['indexSelected']] = jsonFinal
        } else {
            array.push(jsonFinal)
        }

        this.setState({
            arrayItens: array,
            showFormItem: false
        })
    }

    private save() {
        var json = this.state.json as any
        if (!json["nome"] || !json["obrigatorio"] || !json["tipo"] || !json["ordem"] || !json["sgeptipoponto"]) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }
        if (json["tipo"] == 'L') {
            json['itens'] = this.state.arrayItens
        } else {
            json["itens"] = []
        }


        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any
        json['sgeppontodiversos'] = this.props.idPrimary

        this.setState({ isLoading: true })
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if (resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0],
                    arrayItens: resultado[0]['itens']
                })

                return
            }

            this.setState({ isLoading: false })
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private clearJSON() {
        var json = this.state.json as any
        delete json['sgepcadastroimobiliarioconfiguracaoitem']
        delete json['item']
        delete json['valor']

        this.setState({
            json: json
        })
    }
}