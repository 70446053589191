import { SeveralPointConfigInteractor, SeveralPointConfigRequest, SeveralPointConfigStructRequest } from "../../Coordinator/SeveralPointConfig/SeveralPointConfigInteractor";

export class SeveralPointConfigViewModel {
    private interactor: SeveralPointConfigInteractor;

	constructor() {
		this.interactor = new SeveralPointConfigInteractor();
    }
    
    public fetchSave(obj: any): PromiseLike<any> {
        var request = new SeveralPointConfigRequest(
            obj["sgeppontodiversos"] ? parseInt(obj["sgeppontodiversos"]) : 0,
            obj["nome"] ? obj["nome"] : "",
            obj["tipo"] ? obj["tipo"] : "",
            obj["obrigatorio"] ? obj["obrigatorio"] : "",
            obj["ordem"] ? parseInt(obj["ordem"]) : 0,
            obj["itens"],
            obj["sgeptipoponto"] ? obj["sgeptipoponto"] : ""
        )

        return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchDataOne(obj: any): PromiseLike<any> { 
        var request = new SeveralPointConfigStructRequest(
            obj["sgeppontodiversos"] ? parseInt(obj["sgeppontodiversos"]) : 0
        )

        return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result)
			}, e => {
				return rej(e)
			});
		})
    }

    public fetchSelectPointType(): PromiseLike<any> {
        return new Promise((res, rej) => {
          this.interactor.fetchSelectPointType().then((result) => {
            return res(result)
          }, e => {
            return rej(e)
          });
        })
      }
}